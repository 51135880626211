<script setup>
import { CdrText, IconLocationPinStroke, IconXLg } from '@rei/cedar';
import { getPreferredStore } from '@rei/session';
import { ref, computed, onBeforeUnmount, onMounted } from 'vue';
import mitt from 'mitt';

const globalEmitter = mitt();
const preferredStore = ref(null);

const availability = computed(() =>
  preferredStore.value?.availability ? preferredStore.value.availability : null
);

const myReiText = computed(() =>
  preferredStore.value?.isSuggestion
    ? 'Near you'
    : 'My REI'
);

const storeName = computed(() =>
  preferredStore.value?.storeDisplayName
    ? preferredStore.value.storeDisplayName
    : 'Find your store',
);

const availabilityClasses = computed(() => {
  if (!preferredStore.value) return '';

  const { temporarilyClosed } = preferredStore.value;

  return {
    'my-store-nav-button__store--closed':
      temporarilyClosed || availability.value.temporarilyClosed || !availability.value.open,
    'my-store-nav-button__store--opened':
      !temporarilyClosed && !availability.value.temporarilyClosed && availability.value.open,
  };
});

const handleUpdateStore = (newStore) => {
  preferredStore.value = newStore;
};

onMounted(async () => {
  preferredStore.value = await getPreferredStore();
  globalEmitter.on('update-store', handleUpdateStore);
  globalEmitter.on('suggested-store', handleUpdateStore);
});

onBeforeUnmount(() => {
  globalEmitter.off('update-store', handleUpdateStore);
  globalEmitter.off('suggested-store', handleUpdateStore);
});
</script>

<template>
  <button
    id="my-store-nav-button"
    class="my-store-nav-button icon-button"
    data-ui="site-header-action-local"
  >
    <IconXLg class="gnav-icon my-store-nav-button--icon close-icon" />
    <IconLocationPinStroke class="gnav-icon my-store-nav-button--icon location-icon" />
    <div class="my-store-nav-button__text-content">
      <cdr-text tag="span" data-visible="desktop">
        {{ myReiText }}
        <span v-if="availability" :class="availabilityClasses">
          <template v-if="preferredStore.temporarilyClosed || availability.temporarilyClosed">
            Temporarily Closed
          </template>
          <template v-else-if="availability.open && availability.closesAt">
            {{ availability.closesAt.replace('today', '').replace('until', 'til') }}
          </template>
          <template v-else-if="!availability.open && availability.opensAt">
            {{ availability.opensAt }}
          </template>
        </span>
      </cdr-text>
      <cdr-text tag="span" data-visible="desktop">
        {{ storeName }}
      </cdr-text>
    </div>
  </button>
</template>

<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss';

.my-store-nav-button {
  & &--icon {
    fill: #292929;

    @include cdr-md-mq-up {
      fill: #616161;
    }
  }

  .sr-only {
    @include cdr-display-sr-only;
  }

  .close-icon {
    display: none;
  }

  &[aria-expanded='true'] {
    .location-icon {
      display: none;

      @include cdr-md-mq-up {
        display: block;
      }
    }

    .close-icon {
      display: block;

      @include cdr-md-mq-up {
        display: none;
      }
    }
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: nowrap;

    & > [data-visible='desktop'] {
      margin-left: $cdr-space-quarter-x;
    }

    & > span:first-of-type {
      @include cdr-text-utility-sans-100;
    }

    & > span:last-of-type {
      color: #292929;
      text-decoration: underline;
      @include cdr-text-utility-sans-200;
    }
  }

  &__store--opened,
  &__store--closed {
    margin-left: $cdr-space-quarter-x;
  }

  &__store--opened {
    color: #367c3c;
  }

  &__store--closed {
    color: $cdr-color-text-secondary;
  }
}
</style>
