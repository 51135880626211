<script setup>
</script>

<template>
  <div class="my-store-backdrop"></div>
</template>

<style lang="scss">
@import "@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss";

.my-store-backdrop {
  @include cdr-md-mq-down {
    position: fixed;
    visibility: hidden;
    background-color: transparent;
    border: 0;
    padding: 0;
    content: '';
    cursor: pointer;
    transition: visibility 0.75s ease-in-out, background-color 0.75s ease-in-out;
    width: 100vw;
    height: 100vh;
  }
}

.gnav[data-my-store='true'] .my-store-backdrop {
  @include cdr-md-mq-down {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: visible;
  }
}
</style>
