import initSearch from "@rei/search-input-suggestions";

// TODO import this from package
const AUTOSUGGEST_SELECTOR = '[data-js=suggestion-box]';

// TODO add unit tests after entry config of search-input-suggestions completed

/**
 * Initializes search - handles race conditions
 * In prod, the mounting point will almost always be available by the time
 * this code runs. However, during local dev, the page may still be loading
 * the page / global navigation header, so this allows for the fallback scenario
 * where page load has not yet occurred.
 *
 * @param {String} el: The selector for the mounting point for the
 *   search autosuggest component
 * @param {Boolean} toggle: whether or not to init search-input-suggestions
 *   by default.
 * @returns void
 */
const initSearchWithConfig = (
  el = AUTOSUGGEST_SELECTOR,
  toggle = false,
) => {
  // if toggle is false, don't do any of this
  if (!toggle) return;

  // check if the mounting point exists and is an HTML Element
  const isReady = () => {
    const mountingEl = document?.querySelector(el);
    return mountingEl && mountingEl instanceof HTMLElement;
  }

  // if it exists, init search with the toggle set to true
  // otherwise, listen for the load event and then init
  if (isReady()) {
    initSearch(toggle);
  } else {
    window.addEventListener('load', () => {
      initSearch(toggle);
    });
  }
}

export default initSearchWithConfig;
