<script setup>
import { AccountNavButton } from '@rei/account-flyout';
import { addChangeListener } from '@rei/session';
import { reactive, computed, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
  notifications: {
    type: Object,
    default: () => ({}),
  },
});

const state = reactive({
  reactiveUser: props.user,
  accountListener: null,
});

const userGreeting = computed(() => state.reactiveUser && state.reactiveUser.contact && state.reactiveUser.contact.firstName
    ? `Hi, ${state.reactiveUser.contact.firstName}`
    : 'My Account');

const rewardsBalance = computed(() => {
  if (state.reactiveUser) {
    if (state.reactiveUser.membership?.dividendBalance) {
      const { membership: { dividendBalance } } = state.reactiveUser;
      const balance = parseFloat(dividendBalance);
      const formattedBalance = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(balance);

      return formattedBalance;
    }

    if (
      state.reactiveUser.membership &&
      state.reactiveUser.membership?.dividendBalance != null
    ) {
      const { membership: { dividendBalance } } = state.reactiveUser;
      const balance = parseFloat(dividendBalance);
      const formattedBalance = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(balance);
      return formattedBalance === '$0.00' ? '' : `${formattedBalance}`;
    }

    return '';
  }

  return '';
});

const addUserListener = () => {
  state.accountListener = addChangeListener((user) => {
    state.reactiveUser = user;
  });
};

const destroyUserListener = () => {
  if (state.accountListener) {
    state.accountListener.unsubscribe();
    state.accountListener = null;
  }
};

onMounted(() => {
  addUserListener();
});

onBeforeUnmount(() => {
  destroyUserListener();
});

</script>

<template>
  <AccountNavButton
    :user-greeting="userGreeting"
    :rewards-balance="rewardsBalance"
    :expiring-rewards="notifications?.expiringRewards"
  />
</template>
