<script setup>
import { computed, reactive, onMounted, onBeforeUnmount } from 'vue';
import { addChangeListener } from '@rei/session';

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
});

const state = reactive({
  reactiveUser: props.user || null,
  accountListener: null,
});

const addUserListener = () => {
  state.accountListener = addChangeListener((user) => {
    state.reactiveUser = user;
  });
};
const destroyUserListener = () => {
  if (state.accountListener) {
    state.accountListener.unsubscribe();
    state.accountListener = null;
  }
};

const userGreeting = computed(() =>
  state.reactiveUser && state.reactiveUser.contact && state.reactiveUser.contact.firstName
    ? `Hi, ${state.reactiveUser.contact.firstName}`
    : 'My Account',
);

const rewardsBalance = computed(() => {
  if (state.reactiveUser) {
    if (state.reactiveUser.membership?.dividendBalance != null) {
      const balance = parseFloat(state.reactiveUser.membership.dividendBalance);
      const formattedBalance = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(balance);
      return formattedBalance;
    }
    if (state.reactiveUser.membership && state.reactiveUser.membership?.dividendBalance != null) {
      const balance = parseFloat(state.reactiveUser.membership.dividendBalance);
      const formattedBalance = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(balance);
      return formattedBalance === '$0.00' ? '' : `${formattedBalance}`;
    }
    return '';
  }
  return '';
});

onMounted(() => {
  addUserListener();
});

onBeforeUnmount(() => {
  destroyUserListener();
});
</script>

<template>
  <div class="account-link-mobile-container">
    <a
      class="account-link"
      href="/YourAccountInfoInView"
      data-ui="nav-level-1"
      data-analytics-id="rei_nav:account"
      rel="nofollow"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        data-visible="mobile"
        class="gnav-icon"
        aria-hidden="true"
      >
        <use xlink:href="#account-profile"></use>
      </svg>
      <div class="account-link-info-container">
        <span class="account-link__text" data-icon="left">{{ userGreeting }}</span>
        <span
          v-if="rewardsBalance"
          class="account-link__text account-link__rewards-wrapper"
          data-icon="left"
        >
          Rewards:
          <span class="account-link__rewards-span">{{ rewardsBalance }}</span>
        </span>
      </div>
    </a>
    <a
      class="account-link account-link--right"
      href="/youraccount/logout"
      data-ui="nav-level-1"
      data-analytics-id="rei_nav:sign_out"
      rel="nofollow"
    >
      <span class="account-link__text">Sign Out</span>
    </a>
  </div>
</template>
